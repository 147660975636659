import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import './index.css';
import Layout from '../layouts/layout'
import Project from '../components/project';

export default ({data}) => {
  const projects = data.allMarkdownRemark.edges.map(({node}) => {
    return (
      <div key={node.id} className="column is-half is-one-quarter-widescreen">
        <Project projectInfo={node}/>
      </div>
    );
  });

  return (
    <Layout>
      <section className="hero is-large" style={{ position: "relative" }}>
        <Img fluid={data.file.childImageSharp.fluid} style={{ position: "absolute", width: "100%", height: "100%" }}/>
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title is-1 has-text-white">Iteem</h1>
            <p className="title is-3 has-text-white">Personal Website</p>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="content">
            <h1 className="title is-2 has-text-centered">
              Projects
            </h1>
            <p>
              <Link to="/projects/">More projects</Link>
            </p>
          </div>

          <div className="columns is-multiline">
            {projects}
          </div>

          <div className="content">
            <h1 className="title is-2 has-text-centered">
              About Me
            </h1>
            <p>
              Hi, I'm Patrick, a software engineer from Basel, Switzerland. I've studied physics at the University of Basel
              and am currently working at matchmycolor. I also enjoy programming in my free time and some of the results
              you'll find on this page.
            </p>
            <Link to="/about/">Read more</Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    file(relativePath: { eq: "pages/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/projects/(corpuscle|fractals|buddhabrotCL|scc)/index.md$/"}}, sort: {fields: frontmatter___title}) {
      edges {
        node {
          id
          ...ProjectInformation
        }
      }
    }
  }
`;